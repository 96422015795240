import { parseJSON } from '../../../utils/Helper';
import * as type from '../../constants';

const initialState = {
    loadingKeys: false,
    keysCategoryList: [],

    loading: true,
    queryReport: [],

    error: null,
}

export function queryReportReducer(state = initialState, action) {
    switch (action.type) {
        case type.GET_ANANDA_KEY_CATEGORY_LIST:
            return {
                ...state,
                loadingKeys: true,
            }
        case type.GET_ANANDA_KEY_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                keysCategoryList: action.payload?.map(category => ({
                    ...category,
                    'sub_category': category.sub_category.map(sub => ({
                        ...sub,
                        json_key: parseJSON(sub.json_key) ? parseJSON(sub.json_key) :  sub.json_key || null
                    }))
                })),
                loadingKeys: false,
                error: null
            }
        case type.GET_ANANDA_KEY_CATEGORY_LIST_FAILURE:
            return {
                ...state,
                loadingKeys: false,
                error: action.message,
            }

        case type.GET_QUERY_REPORT_DATA:
            return {
                ...state,
                loading: true,
            }
        case type.GET_QUERY_REPORT_DATA_SUCCESS:
            return {
                ...state,
                queryReport: action.payload,
                loading: false,
                error: null
            }
        case type.GET_QUERY_REPORT_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
            
        default:
            return state
    }
}