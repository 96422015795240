import React, { Fragment, useEffect, useState } from 'react'
import './QueryReport.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Divider, Paper, Toolbar, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import WhitePlus from "../../../assets/images/CommonComponent/search.png"
import { Button } from 'react-bootstrap';
import SearchModal from './components/SearchModal/SearchModal';
import {ReactComponent as Column} from "../../../assets/images/CommonComponent/columns.svg"
import SelectColumnModal from './components/SelectColumnModal/SelectColumnModal';
import { getAnandaKeysCategoryList, getQueryReport } from '../../../redux/actions/AnalyticalReportAction/QueryReportAction';
import { parseJSON } from '../../../utils/Helper';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import QueryReportTable from './components/QueryReportTable';
import { ANALYTICAL_REPORT } from '../../../Router/RoutesURL';

const defaultColumns = [
    {
        "label": "First Name",
        "data_type": "string",
        "table": "users",
        "tableLabel": "Guest",
        "json_key": null,
        "selected_json_key": null,
        "column_name": "first_name",
        "for_unique_key_alias": null,
        "row_id": null,
        "extra_key": null,
        "can_delete": false
    },
    {
        "label": "Last Name",
        "data_type": "string",
        "table": "users",
        "tableLabel": "Guest",
        "json_key": null,
        "selected_json_key": null,
        "column_name": "last_name",
        "for_unique_key_alias": null,
        "row_id": null,
        "extra_key": null,
        "can_delete": false
    }
]

  
const MainHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const getColumns = ()=>{
    let d = parseJSON(localStorage.getItem('query')) || {};
    let dynamicCol = d?.columns?.map(item=>({
        id: item.column_name, label: item.label, sortingKey: item.column_name, sort: false, width: '200px', dataType: item.data_type, ...item
    })) || []
    const columns = [
        ...dynamicCol
    ];

    return columns
}

function QueryReport(props) {
    const [openModal, setOpenModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [openSelectColumnModal, setOpenSelectColumnModal] = useState(false)
    const [filter, setFilter] = useState({
        searchText: searchParams.get("search") || '',
      })

    const { activeTab } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const data = useSelector(state => state?.queryReportReducer);

    const getTableData = ()=>{
        let d = parseJSON(localStorage.getItem('query')) || {}
        if(!d.columns){
            d.columns = defaultColumns
            d.condition= 'or'
            localStorage.setItem('query', JSON.stringify(d))
        }
        dispatch(getQueryReport({
            ...d,
            page: searchParams.get("page") ? parseInt(searchParams.get("page"))+1 : 1,
            pageLimit: searchParams.get("pageLimit") || 10,
            ...filter
        }))
    }

    const handleSingleValueFilter = (key)=>{
        let value = filter.searchText
        if(value !== searchParams.get(key)){
          searchParams.delete('page')
          searchParams.delete(key)
  
          let newPair= value ? [[key, value?.trim()]] : [];
          navigate({
            pathname: `${ANALYTICAL_REPORT}`,
            search: `?${createSearchParams([...searchParams.entries(), ...newPair])}`,
          }, {replace: true})
        }
      }

    useEffect(()=>{
        if(activeTab==='queryReport'){
            getTableData()
        }
    }, [activeTab, searchParams])

    React.useEffect(()=>{ 
        if(!data?.keysCategoryList?.length)
            dispatch(getAnandaKeysCategoryList())
    }, [])

    return (
        <div className="queryReport" >
            <Box sx={{ width: '100%', minHeight: '300px',}}>
                <Paper sx={{ width: '100%',Radius: 2 }}>
                    <Toolbar sx={{
                        pl: { sm: 2 },
                        pt: { sm: 1 },
                        pb: { sm: 1 },
                        pr: { xs: 1, sm: 1 },
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    >
                        <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-3'>
                            <MainHeading
                                sx={{ flex: '1 1 100%', }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Report
                            </MainHeading>
                        </div>
                        <div className='d-flex align-items-center'>
                            {/* <div className="input-value mr-2">
                                <input type="text" placeholder='Search...' value={filter.searchText} onChange={(e)=>{setFilter(prev=>({...prev, searchText: e.target.value}))}}   />
                                <img className="mr-2  " src={WhitePlus} alt="" width="14px" height="14px" onClick={()=>{handleSingleValueFilter("search")}} />
                            </div> */}
                            <Button className="btn btn-primary mt-2 advance-search-btn mx-2" type="submit" onClick={()=>setOpenModal(true)}>
                                <img className="mr-2 mb-1 " src={WhitePlus} alt="" width="14px" height="14px" />Advanced Search
                            </Button>
                            <Tooltip title={'Select Columns'}>
                                <Column className='cursor-pointer' fontSize={27} onClick={()=> setOpenSelectColumnModal(true)} />
                            </Tooltip>
                        </div>
                    </Toolbar>
                    </Paper>
                    <div className='dashboard' style={{ height: 'calc(100vh - 185px)'}}>
                        <QueryReportTable
                            checkbox={false}
                            columns={getColumns()}
                            loading={data.loading}
                            data ={data.queryReport?.data || []}
                            pagination={data.queryReport?.list?.length}
                            totalCount={data.queryReport?.total}
                            totalPages={data.queryReport?.total_pages}
                            start={(data.queryReport?.current_page_no - 1) * (data.queryReport?.end-data.queryReport?.start) + 1}
                            end={(data.queryReport?.current_page_no - 1) * (data.queryReport?.end-data.queryReport?.start) + data.queryReport?.list?.length}
                            currentPage={data.queryReport?.current_page_no-1}
                            rowsPerPage={searchParams.get("pageLimit") || 10}
                            filter={filter}
                            getTableData={getTableData}
                        />
                    </div>
            </Box>
            {
                openModal &&
                <SearchModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            }
            {
                openSelectColumnModal &&
                <SelectColumnModal
                    openModal={openSelectColumnModal}
                    setOpenModal={setOpenSelectColumnModal}
                    defaultColumns={defaultColumns}
                />
            }
        </div>
    )
}

export default QueryReport