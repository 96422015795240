import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './SearchModal.scss'
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryReport } from '../../../../../redux/actions/AnalyticalReportAction/QueryReportAction';
import {ReactComponent as AddIcon} from '../../../../../assets/images/CommonComponent/WhitePlus.svg'
import { parseJSON, validateDate } from '../../../../../utils/Helper';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import calendarIcon from '../../../../../assets/images/CommonComponent/calendarIcon.png';
import { TINYINT, DATETIME, DATE, STRING, INT, JSON_TYPE } from '../../../../../Config/Constant';
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/CommonComponent/trash.svg"
import {ReactComponent as EditIcon} from "../../../../../assets/images/CommonComponent/pencil.svg"
import { useSearchParams } from 'react-router-dom';


const operatorList = [
  { label: "Is", value: "is", data_type: [TINYINT, DATETIME, DATE, STRING, INT] },
  { label: "Is Not", value: "is_not",  data_type: [TINYINT, DATETIME, DATE, STRING, INT] },
  { label: "Contains", value: "contains",  data_type: [STRING] },
  { label: "Does Not Contain", value: "not_contains",  data_type: [STRING] },
  { label: "Starts With", value: "starts_with",  data_type: [STRING] },
  { label: "Ends With", value: "ends_with",  data_type: [STRING] },
  { label: "Less Than", value: "less_than",  data_type: [DATETIME, DATE, INT] },
  { label: "Less Than Equal To", value: "less_than_eq",  data_type: [DATETIME, DATE, INT] },
  { label: "Greater Than", value: "greater_than",  data_type: [DATETIME, DATE, INT] },
  { label: "Greater Than Equal To", value: "greater_than_eq",  data_type: [DATETIME, DATE, INT] },
  { label: "Empty", value: "empty", data_type: [TINYINT, DATETIME, DATE, STRING, INT] },
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '500px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

export default function SearchModal({openModal, setOpenModal, onYes, text, hide}) {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const data = useSelector(state => state?.queryReportReducer);
  const [filter, setFilter] = React.useState(localStorage.getItem('query') ? JSON.parse(localStorage.getItem('query'))  : {condition: 'or', filter: []})
  const [currentfilter, setCurrentFilter] = React.useState(null)

  const handleClose = () => setOpenModal(false);

  const handleColumnChange = (e)=>{
    setCurrentFilter(prev=>({
      editIndex: currentfilter?.editIndex,
      label: e.label,
      column_name: e.column_name,
      data_type: e.data_type,
      table: e.table,
      json_key: e.json_key,
      for_unique_key_alias: e.for_unique_key_alias,
      row_id: e.row_id,
      extra_key: e.extra_key,

      inputValue: e.data_type===TINYINT ? "1" : "",
      ...(e.data_type===TINYINT && {operatorLabel: 'Is', "operator": 'is'})
    }));
  }

  const generateSubOptionslabel = (item, level=1)=>{
    let s = item?.[`nested_data_level${level}`]?.sub_category;
    if(!s)
      return ''
    return ` \n----> ${s} ${generateSubOptionslabel(item, level+1)}`
  }
  
  const handleOperatorChange = (e)=>{
    setCurrentFilter(prev=>{
      return ({
        ...prev,
        "operatorLabel": e.label,
        "operator": e.value,
        ...(e.value==='empty' && {"inputValue": ''})
      })
    });
  }

  const handleSearch =()=>{
    let getData = parseJSON(localStorage.getItem('query')) || {}
    localStorage.setItem('query', JSON.stringify({...filter, columns: getData?.columns || []}));
    dispatch(getQueryReport({
      ...filter,
      page: searchParams.get("page") ? parseInt(searchParams.get("page"))+1 : 1,
      pageLimit: searchParams.get("pageLimit") || 10,
    }))
    setOpenModal(false)
  }

  const handleDelete = (index)=>{
    setFilter(prev=>{
      let d = JSON.parse(JSON.stringify(prev))
      d.filter.splice(index, 1);
      return d;
    })
  }

  const handleAdd = ()=>{
    setFilter(prev=>{
      let currentData = {...currentfilter}
      let editIndex = currentData?.editIndex
      if(editIndex>=0){
        let temp = JSON.parse(JSON.stringify(prev));
        delete currentData.editIndex
        temp.filter[editIndex]= currentData;
        return temp;
      }else{
        return {
          ...prev,
          filter: prev.filter ? [
            ...prev.filter,
            currentData
          ] : [currentData]
        }
      }
      
    })
    setCurrentFilter(null);
  }

  const handleEdit = (index)=>{
    setCurrentFilter({
      ...filter.filter[index],
      editIndex: index
    })
  }

  const handleReset = ()=>{
    // setCurrentFilter(null);
    setFilter({condition: 'or', filter: []})
  }

  const handleInputValueChange = (e)=>{
    setCurrentFilter(prev=>({
      ...prev,
      "inputValue": e.target.value
    }))
  }
  
  const handleBooleanInput = (value)=>{
    setCurrentFilter(prev=>({
      ...prev,
      "inputValue": value
    }))
  }

  const handleDateInput = (value)=>{
    let date=moment(value).format('YYYY-MM-DD HH:mm:ss');
    setCurrentFilter(prev=>({
      ...prev,
      // "inputValue": prev.data_type===DATE ? date: (prev.inputValue ? `${date}${prev.inputValue.slice(10)}` : `${date} 00:00:00`)
      "inputValue": prev.data_type===DATE ? date.slice(0, 10) : date
    }))
  }

  const handleTimeInput = (value)=>{
    let time=moment(new Date(value)).format('HH:mm:ss');
    setCurrentFilter(prev=>{
      let date = prev?.inputValue?.slice(0, 11)
      return({
        ...prev,
        "inputValue": prev.inputValue ? `${date} ${time}` : `${moment().format('YYYY-MM-DD')} ${time}`
      })
    })
  }

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="search-modal h-100 d-flex flex-column">
            <div className="">
              <div className="title">Advanced Search</div>
            </div>
            <div className="hr"></div>
            <div className="my-2 flex-grow-1 d-flex">
              <div className="filter-box pr-3">
                <div>
                  <Select
                    className="name-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="color"
                    isLoading={data?.loadingKeys}
                    styles={{
                      container: (provided,state) => ({
                          ...provided,
                          width: "360px",
                          
                        }),
                    }}
                    value={currentfilter?.label ? {label: currentfilter?.label, value: currentfilter?.label} : null}
                    options={data?.keysCategoryList?.map(category => ({
                      label: category.category,
                      value: category.table,
                      options: category.sub_category.map(sub => ({
                          label: sub.sub_category_title,
                          value: sub.sub_category_title,
                          column_name: sub.column_name,
                          tableLabel: category.table,
                          table: sub.table,
                          data_type: sub.data_type,
                          json_key: sub.json_key,
                          for_unique_key_alias: sub.for_unique_key_alias,
                          row_id: sub.row_id,
                          extra_key: sub.extra_key,
                      }))
                    }))}
                    
                    placeholder='Select Column'
                    onChange={handleColumnChange}          
                    />
                  {<>{generateSelectOptions({json: currentfilter?.json_key, currentfilter, setCurrentFilter})}</>}
                  {
                    currentfilter?.data_type!==TINYINT &&
                    <Select
                      className="name-select mt-2"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="color"
                      styles={{
                        container: (provided,state) => ({
                            ...provided,
                            width: "360px",
                            
                          }),
                      }}
                      value={currentfilter?.operatorLabel ? operatorList.find(item=> item.label === currentfilter?.operatorLabel) : null}
                      options={operatorList?.filter(item=> item.data_type.includes(currentfilter?.data_type)) || []}
                      placeholder='Select Operator'
                      onChange={handleOperatorChange}
                      isDisabled={!currentfilter?.label}
                  />
                  }

                {[INT, STRING].includes(currentfilter?.data_type) && currentfilter?.operator!=='empty' &&<input className="input-value mr-2" placeholder='Enter' type={(currentfilter?.data_type===STRING) ? 'text' : 'number'} value={currentfilter?.inputValue} onChange={handleInputValueChange} />}
                
                {
                  currentfilter?.data_type === TINYINT &&
                  <div className='d-flex align-items-center justify-content-center mt-2'>
                    <RadioButton 
                      name="boolean" 
                      label="True" 
                      value="1" 
                      checked={currentfilter?.inputValue==='1'} 
                      onChange={handleBooleanInput}
                    />
                    <RadioButton 
                      name="boolean" 
                      label="False" 
                      value="0" 
                      checked={currentfilter?.inputValue==='0'} 
                      onChange={handleBooleanInput}
                    />
                  </div>
                }
                {
                  [DATE, DATETIME].includes(currentfilter?.data_type) &&
                  <div className='dateBlock mt-2'>
                    {currentfilter?.inputValue == "" ?
                        <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Select Date</p></div>
                        : 
                        <div className="dayDateTextDiv" >
                            <p className="dayDateText d-flex ">
                                {currentfilter.data_type === DATETIME ? (moment(currentfilter?.inputValue, "YYYY-MM-DD hh:mm A").format('DD/MM/YYYY hh:mm A')) : (moment(currentfilter?.inputValue, "YYYY-MM-DD hh:mm A").format('DD/MM/YYYY'))} 
                            </p>
                        </div>
                    }
                    <div className='datePickerDiv'>
                        <ReactDatePicker
                            onChange={date => { handleDateInput(date) }}
                            popperPlacement={"bottom"}
                            customInput={
                                <img src={calendarIcon} className="calIcon" alt="" />
                            }
                            showTimeInput={currentfilter?.data_type===DATETIME}
                            timeInputLabel={'Time: '}       
                            selected={validateDate(currentfilter?.inputValue) ? new Date(currentfilter?.inputValue): new Date()}    
                            showMonthDropdown={true}        
                            showYearDropdown={true}        
                        />
                    </div>
                  </div>
                }
                {/* {
                  (currentfilter?.data_type===DATETIME) && 
                  <div className='mt-2'>
                    <TimeSelector 
                      ampm={true}
                      width={'100%'}
                      height={'30px'}
                      setTime={handleTimeInput}
                      time={currentfilter?.inputValue ? new Date(currentfilter.inputValue) : ''}
                    /> 
                  </div>
                } */}

              </div>
                <div className='d-flex justify-content-center mt-4'>
                  <button className="add-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleAdd} disabled={!(currentfilter?.label && currentfilter?.operator && (currentfilter?.operator !=='empty' ? currentfilter?.inputValue: true))}
                  >
                    <AddIcon width={'10px'} className='mr-2' />
                    <span>Add</span>
                  </button>
                  <button className="reset-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleReset}
                  >
                    <span>Reset</span>
                  </button>
                </div>
              </div>
              <div className="vhr"></div>
              <div className="query-box flex-grow-1 ml-2">
                <div className='d-flex align-items-center'>
                  <span className='label mr-2'>Select Criteria: </span>
                  <RadioButton 
                    name="logical-operator" 
                    label="Any Criteria" 
                    value="or" 
                    checked={filter.condition==='or'} 
                    onChange={(value)=> setFilter(prev=>({...prev, condition: value}))}
                  />
                  <RadioButton 
                    name="logical-operator" 
                    label="All Criteria" 
                    value="and" 
                    checked={filter.condition==='and'} 
                    onChange={(value)=> setFilter(prev=>({...prev, condition: value}))}
                  />
                </div>
                <div className="query w-100 mt-3">
                  {
                    filter.filter?.map((item, index)=>{
                      let subLabels = generateSubOptionslabel(item, 1);
                      return (
                      <div className='bg-light d-flex align-items-center justify-content-between my-2 round p-2' key={index}>
                        <div className='d-flex mr-2'>
                          {index!==0 ? <span className='bg-dark rounded p-1 py-0 align-self-start  text-uppercase text-white mr-2'>{filter.condition}</span> : ''} 
                            <div>
                              <span className='font-weight-bold' style={{color: '#5ebd78'}}>{item.label} <>{subLabels}</> {item.operatorLabel} </span> 
                              {
                                item.data_type === TINYINT ? (item?.inputValue==='1'? 'True': 'False') :
                                item.data_type === DATE ? moment(item?.inputValue)?.format('DD MMM, YYYY') :
                                item.data_type === DATETIME ? moment(item?.inputValue)?.format('DD MMM, YYYY hh:mm A'):
                                item?.inputValue
                              }
                            </div>
                         </div>
                        <div className='flex-shrink-0'>
                          <span className='cursor-pointer mr-1' onClick={()=>handleEdit(index)}><EditIcon fill={'#747474'} fontSize={'16px'} /></span>
                          <span className='cursor-pointer' onClick={()=>handleDelete(index)}><DeleteIcon fill={'#747474'} width={'12px'} /></span>
                        </div>
                      </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <button className="add-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleSearch}>
                <span>SEARCH</span>
              </button>
              <button className="reset-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleClose}>
                <span>CANCEL</span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const generateSelectOptions = ({json, currentfilter, setCurrentFilter, level=1}) => {
  if(!json || typeof json === 'string')
    return;

  const handleSubCategoryChange = (e)=>{
    setCurrentFilter(prev=>({
      ...prev,
      data_type: e.data_type,

      [`nested_data_level${level}`]: {
        sub_category: e.label,
        data_type: e.data_type,
        json_key: e.json_key,
      },

      selected_json_key: Array.isArray(e.json_key) ?  '' : e.json_key,
      
      inputValue: e.data_type===TINYINT ? "1" : "",
      ...(e.data_type===TINYINT && {operatorLabel: 'Is', "operator": 'is'})
    }));
  }

  return (
   <>
    {
       Array.isArray(json)  &&
       <Select
         className="name-select mt-2"
         classNamePrefix="select"
         isSearchable={true}
         name="color"
         styles={{
           container: (provided,state) => ({
               ...provided,
               width: "360px",
               
             }),
         }}
         value={currentfilter[[`nested_data_level${level}`]]?.sub_category ? {label: currentfilter[[`nested_data_level${level}`]]?.sub_category, value: currentfilter[[`nested_data_level${level}`]]?.json_key} : null}
         options={json?.map((item)=>({
           label: item.sub_category,
           value: item.sub_category,
           json_key: item.json_key,
           data_type: item.data_type,
         })) || []}
         placeholder='Select'
         onChange={handleSubCategoryChange}
     />
    }
    {generateSelectOptions({
      json: currentfilter[`nested_data_level${level}`]?.json_key, 
      currentfilter,
      setCurrentFilter, 
      level: level+1
    })}
   </>
  )
};