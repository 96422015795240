import { call, put, takeEvery } from 'redux-saga/effects'
import {
    getAnandaKeyCategory_API,
    getReportForQuery_API,
} from '../../../Config/Api';
import { createNotification } from '../../../Config/NotificationToast';
import { instance } from "../../auth/axiosInstance";
import { logOut } from '../../../utils/Helper';

export default function* queryReport() {
    yield takeEvery('GET_ANANDA_KEY_CATEGORY_LIST', getAnandaKeysCategory);
    yield takeEvery('GET_QUERY_REPORT_DATA', getQueryReport);
}

const getAnandaKeysCategoryApi = async (url) => {
    try {
        const response = await instance.get(url);
        return response;
    } catch (errors) {
        console.log("errors", errors)
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getAnandaKeysCategory(action) {
    const URL = `${getAnandaKeyCategory_API}`
    try {
        const response = yield call(getAnandaKeysCategoryApi, URL);
        if (response.statusCode === 200) {
            yield put({ type: 'GET_ANANDA_KEY_CATEGORY_LIST_SUCCESS', payload: response.data });
        } else {
            if (response.statusCode === 401) {
                logOut('error', response.message)
            } else {
                createNotification('error', response.message);
                yield put({ type: 'GET_ANANDA_KEY_CATEGORY_LIST_FAILURE', message: response.message });
            }
        }
    } catch (e) {
        console.log(e)
        yield put({ type: 'GET_ANANDA_KEY_CATEGORY_LIST_FAILURE'});
    }
}

const getQueryReportApi = async (data) => {
    try {
        const response = await instance.post(getReportForQuery_API, {
            data,
        });
        return response;
    } catch (errors) {
        console.log("errors", errors);
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}
function* getQueryReport(action) {
    try {
        
        let data=action.data
        const BODY = {
            "page": data.page || 1,
            "limit": data.pageLimit || 10,
            "filter_text": data.searchText || "",
            "and_or_operator": data.condition || 'or',
            "include_fields": data.columns?.map(item=>{
                return {
                    "table": item.table,
                    "column_name": item.column_name,
                    "for_unique_key_alias" : item.for_unique_key_alias || '',
                    "json_key": item.selected_json_key || '',
                    "row_id": item.row_id || 0,
                }
            }) || [],
            "filter_fields": data.filter?.map(item=>({
                "table": item.table,
                "column_name": item.column_name,
                "row_id": item.row_id || 0,
                "json_key": item.selected_json_key || '',
                "operator": item.operator,
                "for_unique_key_alias" : item.for_unique_key_alias || '',
                "extra_key": item.extra_key || '',
                "data_type": item.data_type || '',
                "value" : item.inputValue
            })) || [],
            
          };
        console.log(BODY)
        const response = yield call(getQueryReportApi, BODY);
        if (response.statusCode === 200) {
            yield put({ type: 'GET_QUERY_REPORT_DATA_SUCCESS', payload: response.data });
            // createNotification('success', response.message);

        } else {
            if (response.statusCode === 401) {
                logOut('error', response.message);
            } else {
                createNotification('error', response.message);
                yield put({ type: 'GET_QUERY_REPORT_DATA_FAILURE', message: response.message });
            }
            
        }
        
    } catch (e) {
        console.log(e);
        yield put({ type: 'GET_QUERY_REPORT_DATA_FAILURE', message: e.message });
    }
}
