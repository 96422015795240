import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { ReactComponent as SortIcon} from '../../../../assets/images/CommonComponent/arrow-down.svg';
import styled from "@emotion/styled";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { ANALYTICAL_REPORT } from "../../../../Router/RoutesURL";
import { CircularProgress, TablePagination, tablePaginationClasses } from "@mui/material";
import { ReactComponent as arrowDownIcon} from '../../../../assets/images/CommonComponent/downArrow.svg'
import moment from "moment";
import { DATE, DATETIME, TINYINT } from "../../../../Config/Constant";
import { validateDate } from "../../../../utils/Helper";
import redCross from "../../../../assets/images/CommonComponent/redCross.svg"
import greenCheck from "../../../../assets/images/CommonComponent/GreenCheck.svg"
import { Col } from "react-bootstrap";

const TableRowStyled = styled(TableRow)`
//   &:nth-of-type(odd) td {
//     border: none;
//     padding-bottom: 0;
//   },

//   &:nth-of-type(odd) td:first-of-type {
//     border-left: 2px solid #DDDDDD;
//   },

//   &:nth-of-type(odd) td:last-child {
//     border-right: 2px solid #DDDDDD;
//   },

//   &:nth-of-type(even) td {
//     border-top: 2.5px solid #DDDDDD;
//     background-color: #F5F5F5;
//   },

//   &:nth-of-type(even) td:first-of-type {
//     border-left: 2px solid #DDDDDD;
//   },

//   &:nth-of-type(even) td:last-child {
//     border-right: 2px solid #DDDDDD;
//   },
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '500 !important',
    zIndex: 0,
    color: "#AAAAAA",
    padding: '5px',
    wordBreak: "break-word",
    fontSize: "14px",
    textTransform: 'uppercase',
    borderTop: "1px solid #DDDDDD",
  },
  


  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    padding: '6px 6px 6px 9px',
    wordBreak: "break-word",
    fontSize: "14px",
    height: 'fit-content'
  },

  [`&.${tableCellClasses.body}:first-of-type`]: {
    textTransform: 'capitalize',
    // borderLeft: '0.5px solid #74613C',
    padding: '6px 6px 6px 15px',
  },
}));

const TablePaginationStyled = styled(TablePagination)(({ theme }) => ({
  fontSize: '13px',

  [`& .${tablePaginationClasses.select}`]: {
    padding: '4px 8px',
    // borderRadius: '8px',
    // backgroundColor: '#F5F3EC',
    border: '1px solid #DDDDDD'
  },

  [`& .${tablePaginationClasses.select}, & .${tablePaginationClasses.select}:focus`]: {
    borderRadius: '5px',
    backgroundColor: 'white',
    border: '1px solid #DDDDDD'
  },

  [`& .${tablePaginationClasses.selectLabel}, & .${tablePaginationClasses.displayedRows}`]: {
    margin: '0',
  },

  [`& .${tablePaginationClasses.selectIcon}`]: {
    top: '12px',
    right: '7px',
    width: '9px'
  },


  [`& .${tablePaginationClasses.actions} button`]: {
    backgroundColor: 'white',
    marginRight: '5px',
    padding: '3px 5px',
    border: '1px solid #DDDDDD',
    borderRadius: '5px'

  },

  [`& .${tablePaginationClasses.actions} svg`]: {
    width: '20px'
  },

}));

const generateSubOptionslabel = (item, level=1)=>{
    let s = item?.[`nested_data_level${level}`]?.sub_category;
    if(!s)
      return ''
    return ` \n- ${s} ${generateSubOptionslabel(item, level+1)}`
  }

const QueryReportTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,
  filter,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const [order, setOrder] = useState(filter.order);
  const [orderBy, setOrderBy] = useState(filter.orderBy);
  const [selected, setSelected] = useState([]);


  const handleChangePage = (e, newPage) => {
    window.scrollTo(0, 0)
      searchParams.delete("page")
      navigate({
        pathname: `${ANALYTICAL_REPORT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
      }, {replace: true})
  };

  const handleChangeRowsPerPage = (e) => {
    window.scrollTo(0, 0)
    let value=parseInt(e.target.value, 10);
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      searchParams.delete("page")
      navigate({
        pathname: `${ANALYTICAL_REPORT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  const isSelected = (id, flatId) => selected.some(item => item.subscriberId === id && item.flatId === flatId);


  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  useEffect(()=>{
    setOrder(filter.order)
    setOrderBy(filter.orderBy)
  }, [filter])

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px", paddingBottom: '10px' }}
      className="status-report-table-component h-100 "
    >
      <TableContainer
        sx={{minHeight: 'calc(100% - 52px)', maxHeight: 'calc(100% - 52px)'}}
        className="bg-white"
      >
        {
          loading ? 
          <div className="text-center border-top"><CircularProgress size={35} className='mt-5' sx={{'color': '#74613C'}}/></div>:
          !data?.length ?
          <div className="text-center border-top pt-4 text-muted" style={{fontSize: '14px'}}>No Records</div>
          :
          <Table stickyHeader className="h-100"  aria-label="sticky table">
            <TableHead className="position-sticky top-0">
              {/* {renderHeaders(columns)} */}
              <TableRow>
              {columns?.map((item, ind) => {
                return (
                  <TableCellStyled
                    size="small"
                    key={ind}
                    align={"left"}
                    sx={{
                      width: '200px',
                      minWidth: '200px',
                      verticalAlign: 'top' 
                    }}
                  >
                    {item?.label}
                    <br />
                    {generateSubOptionslabel(item, 1)}
                  </TableCellStyled>
                )
              })}
            </TableRow>
            </TableHead>
            <TableBody>
              {
                  loading ?
                  <TableRow >
                    <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><div className='text-center mb-3'><CircularProgress size={35} sx={{'color': '#74613C'}}/></div></TableCellStyled>
                  </TableRow>
                  :
                  <>
                    {!!data?.length && data?.map((row, rowIndex) => {
                        const isRowSelected = isSelected(row?.subscriberId, row.flatId);

                        return (
                          <React.Fragment 
                            key={`${rowIndex}`}
                          >
                            <TableRowStyled
                              role="checkbox"
                              aria-checked={isRowSelected}
                              tabIndex={-1}
                              selected={isRowSelected}
                            >
                              {
                              columns?.map((item, index)=>{
                                let val = row[item.selected_json_key ? `${item.for_unique_key_alias}${item.selected_json_key}` :(item?.for_unique_key_alias || item.id)]
                                  return (
                                  <TableCellStyled size="small" align="left" key={index}>
                                      {
                                        item.dataType===DATETIME && validateDate(val) ? moment(val).format('DD MMM, YYYY HH:mm A') :
                                        item.dataType===DATE && validateDate(val) ? moment(val).format('DD MMM, YYYY') :
                                        item.dataType===TINYINT ? (val===1 ? <img className="mainIcon mr-1 " src={greenCheck} alt="" width="22px" height="22px" /> : <img className="mainIcon mr-1 " src={redCross} alt="" width="22px" height="22px" />) :
                                        val?.toString() || '-'
                                      }
                                  </TableCellStyled>)
                              })}
                            </TableRowStyled>
                          </React.Fragment>
                        );
                      })}
                  </>
                }
            </TableBody>
          </Table>
        }
      </TableContainer>

      {!!pagination && !!data?.length && (
        <div className="mx-4">
          <TablePaginationStyled
            disabled={loading}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount || 0}
            rowsPerPage={rowsPerPage || 10}
            page={currentPage || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: arrowDownIcon,
            }}
        />
        </div>
      )}
    </Box>
  );
};

QueryReportTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default QueryReportTable;

function calculateJsonDepth(arr) {
  if(!arr)
    return 0;

  let depth = 1;

  if (Array.isArray(arr)) {
      let maxDepth = 0;
      arr.forEach(item=>{
        maxDepth = Math.max(maxDepth, calculateJsonDepth(item.json_key));
      })
      depth += maxDepth;
  }

  return depth;
}

function calculateColumnDepth(columns) {
  let depth = 0;
  columns.forEach((item)=>{
    depth = Math.max(depth, calculateJsonDepth(item.json_key));

  })
  return depth;
}

function collectJsonKeysAtSpecificLevel(obj, targetLevel, currentLevel = 1, result = []) {
  if (currentLevel === targetLevel) {
    if(!obj?.json_key){
      result.push(obj?.json_key);
    }else if(Array.isArray(obj?.json_key)){
      obj?.json_key?.forEach(item=>{
        result.push({
          label: item.sub_category,
          data_type: item.data_type,
          json_key: item.json_key
        })
      })
    } else{
      result.push({
        label: obj?.json_key?.sub_category,
        data_type: obj?.json_key?.data_type
      })
    }
    
    return result;
  }
  
  if (typeof obj === 'object' && obj !== null) {
    if(typeof obj?.json_key==='string'){
      result.push(undefined)
    }else{
      obj?.json_key?.forEach(item=>{
        collectJsonKeysAtSpecificLevel(item, targetLevel, currentLevel + 1, result);
      })
    }
  }

  return result;
}
function calculateSpanRequired(arr) {
  if(!arr || !arr?.json_key)
    return 1;

  if(typeof arr?.json_key === 'string')
    return 1;

  let sum=0;
  arr?.json_key?.forEach((item)=>{
    sum+=calculateSpanRequired(item);
  })

  return sum;
}



const renderHeaders = (columns) => {

  return (
    <React.Fragment>
      {
        Array.from({ length: calculateColumnDepth(columns) || 1 }, (_, index) => index)?.map((i)=>{
          return (
            <TableRow key={i}>
            {columns?.map((column, ind) => {
              let result = []
              result = i > 0 ? collectJsonKeysAtSpecificLevel(column, i, 1, result) : []
              let columnsData = i==0 ? [column] : result
              return (
                <React.Fragment key={ind}>
                  {
                    columnsData?.length ===0 ?
                    <TableCellStyled
                    size="small"
                    align={"left"}
                    sx={{
                      width: '200px',
                      minWidth: '200px',
                    }}
                    >
                  </TableCellStyled>
                  :
                  columnsData?.map((item, index)=>{
                      let span = calculateSpanRequired(item)
                      return (
                        <TableCellStyled
                          colSpan={span}
                          size="small"
                          key={index}
                          align={"left"}
                          sx={{
                            width: '200px',
                            minWidth: '200px',
                            verticalAlign: 'top' 
                          }}
                        >
                          {item?.label}
                        </TableCellStyled>
                      )
                    })
                  }
                </React.Fragment>
              )
            })}
            </TableRow>
          )
        })
      }
    </React.Fragment>  
  )
};