import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './SelectColumnModal.scss'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryReport } from '../../../../../redux/actions/AnalyticalReportAction/QueryReportAction';
import { parseJSON } from '../../../../../utils/Helper';
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/CommonComponent/redCross.svg"
import {ReactComponent as DragIcon} from "../../../../../assets/images/CommonComponent/drag.svg"
import { JSON_TYPE } from '../../../../../Config/Constant';
import DraggableComponent from '../../../../../components/DraggableComponent/DraggableComponent';
import { arrayMove, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSearchParams } from 'react-router-dom';

const generateSubOptionslabel = (item, level=1)=>{
  let s = item?.[`nested_data_level${level}`]?.sub_category;
  if(!s)
    return ''
  return ` \n----> ${s} ${generateSubOptionslabel(item, level+1)}`
}


function findIfAllSubOptionsSelected(data, columns) {
  if(typeof data === 'string'){
    if(columns.find(item=> item.selected_json_key === data))
      return true;
    return false;
  }

  let vis= true;
  data?.forEach((item)=>{
    vis = vis  && findIfAllSubOptionsSelected(item.json_key, columns);
  })

  return vis;
}
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '480px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

const groupedData = (data)=>{
  let newData = data.reduce((acc, curr) => {
    const tableLabel = curr.tableLabel;
  
    if (!acc.some(item => item.label === tableLabel)) {
      acc.push({
        label: tableLabel,
        data: []
      });
    }
  
    const group = acc.find(item => item.label === tableLabel);
    group.data.push({
      ...curr
    });
  
    return acc;
  }, []);

  return newData
}

export default function SelectColumnModal({openModal, setOpenModal}) {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const data = useSelector(state => state?.queryReportReducer);
  let localData = parseJSON(localStorage.getItem('query')) || {}
  const [columns, setColumns] = React.useState(localData?.columns || [])
  const [currentColumn, setCurrentColumn] = React.useState({})

  const handleClose = () => setOpenModal(false);

  const handleColumnChange = (e)=>{
    setCurrentColumn(prev=>{
      let d = {
        label: e.label,
        data_type: e.data_type,
        table: e.table,
        tableLabel: e.tableLabel,
        json_key: e.json_key,
        selected_json_key: e.json_key,
        column_name: e.column_name,
        for_unique_key_alias: e.for_unique_key_alias,
        row_id: e.row_id,
        extra_key: e.extra_key,
      };
      if(e.data_type !== JSON_TYPE){
        setColumns(prev=>([...prev, d]))
      }else{
        return d;
      }
    });
  }
  
  
  const handleAdd = ()=>{
    let getData = parseJSON(localStorage.getItem('query'))
    getData = {
      condition: getData?.condition || 'or',
      filter: getData?.filter || []
    }
    localStorage.setItem('query', JSON.stringify({...getData, columns}));
    dispatch(getQueryReport({
      ...getData, columns,
      page: searchParams.get("page") ? parseInt(searchParams.get("page"))+1 : 1,
      pageLimit: searchParams.get("pageLimit") || 10,

    }))
    setOpenModal(false)
  }

  const handleDelete = (index)=>{
    setColumns(prev=> {
      let data = [...prev]
      data.splice(index, 1);
      return data;
    })
  }

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="select-column-modal h-100 d-flex flex-column">
            <div className="">
              <div className="title">Select Columns To View in Table</div>
            </div>
            <div className="hr mb-3"></div>
            <div className="d-flex flex-grow-1">
              <div>
                <Select
                  className="name-select"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="color"
                  isLoading={data?.loadingKeys}
                  value={currentColumn?.label ? {label: currentColumn?.label, value: currentColumn?.label} : null}
                  options={data?.keysCategoryList?.map(category => ({
                    label: category.category,
                    value: category.table,
                    options: category.sub_category.map(sub => ({
                        label: sub.sub_category_title,
                        value: sub.sub_category_title,
                        tableLabel: category.category,
                        table: sub.table,
                        data_type: sub.data_type,
                        column_name: sub.column_name,
                        json_key: sub.json_key,
                        for_unique_key_alias: sub.for_unique_key_alias,
                        row_id: sub.row_id,
                        extra_key: sub.extra_key,
                        isDisabled: sub.data_type===JSON_TYPE ? findIfAllSubOptionsSelected(sub?.json_key, columns) : columns.find(e=> e.label === sub.sub_category_title)
                    }))
                  }))}
                  
                  placeholder='Select Column'
                  onChange={handleColumnChange}          
                  />
                  {<>{generateSelectOptions({json: currentColumn?.json_key, currentColumn, columns, setCurrentColumn, setColumns})}</>}
              </div>
              <div className=" mb-3 flex-grow-1 overflow-y-scroll ml-3 border p-2 rounded column-list-box" style={{height: '330px'}} >
                {/* {
                     groupedData(columns)?.map((item, index)=>{
                        return (
                              <div className='col-block' key={index}>
                                  
                                  <div>
                                      {
                                        item.data?.map((subCategory, subIndex)=>{
                                              return (
                                                  <div className='ml-2 p-1 shadow-sm my-2 d-flex flex-column justify-content-center' key={subIndex} >
                                                      <div className='d-flex align-items-center'>
                                                        <DragIcon className='cursor-pointer flex-shrink-0 mr-2' fontSize={'16px'} /> 
                                                        <div className="label flex-shrink-0 ">{item?.label} - </div>
                                                      </div>
                                                      <div className='d-flex justify-content-between'>
                                                        <div className="">{subCategory.label} {generateSubOptionslabel(subCategory, 1)} </div>
                                                        <DeleteIcon onClick={()=>{handleDelete(subCategory.index)}} className='cursor-pointer flex-shrink-0' width={'14px'} />
                                                      </div>
                                                  </div>
                                              )
                                          })
                                      }
                                  </div>
                              </div>
                          )
                      }) 
                  } */}
                  
                <DraggableComponent
                  data={columns?.map((item, index)=>({...item, id: index}))}
                  setData={setColumns}
                  component={<>
                    {columns.map((col, index) => (
                      <Column key={index} item={{...col, id: index}} />
                      ))}
                  </>}
                />
                  
              </div> 
            </div>
            <div className='d-flex mt-3 justify-content-center'>
              <button className="add-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleAdd}>
                <span>Show Selected Columns</span>
              </button>
              {/* <button className="reset-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleClose}>
                <span>Restore Default</span>
              </button> */}
              <button className="reset-btn flex-shrink-1 mr-2 d-flex items-center justify-content-between" type='submit' onClick={handleClose}>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}


const generateSelectOptions = ({json, currentColumn, columns, setCurrentColumn, setColumns, level=1}) => {
  if(!json || typeof json === 'string')
    return;

  const handleSubCategoryChange = (e)=>{
    setCurrentColumn(prev=>{
      let d = {
        ...prev,
        data_type: e.data_type,
  
        [`nested_data_level${level}`]: {
          sub_category: e.label,
          data_type: e.data_type,
          json_key: e.json_key,
        },
        selected_json_key: Array.isArray(e.json_key) ?  '' : e.json_key,
      }
      if(e.data_type !== JSON_TYPE){
        setColumns(prev=>([...prev, d]))
      }else{
        return d;
      }
    })
  }

  return (
   <>
    {
       Array.isArray(json)  &&
       <>
        <Select
          className="name-select mt-2"
          classNamePrefix="select"
          isSearchable={true}
          name="color"
          styles={{
            container: (provided,state) => ({
                ...provided,
                width: "360px",
                
              }),
          }}
          value={currentColumn[[`nested_data_level${level}`]]?.sub_category ? {label: currentColumn[[`nested_data_level${level}`]]?.sub_category, value: currentColumn[[`nested_data_level${level}`]]?.json_key} : null}
          options={json?.map((item)=>({
            label: item.sub_category,
            value: item.sub_category,
            json_key: item.json_key,
            data_type: item.data_type,
            isDisabled: findIfAllSubOptionsSelected(item?.json_key, columns) 
          })) || []}
          placeholder='Select'
          onChange={handleSubCategoryChange}
        />
        
       </>
    }
    {
      generateSelectOptions({
        json: currentColumn[`nested_data_level${level}`]?.json_key, 
        currentColumn,
        columns,
        setCurrentColumn, 
        setColumns,
        level: level+1
      })
    }
   </>
  )
};

export const Column = ({item}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div className='ml-2 p-1 shadow-sm my-2 d-flex flex-column justify-content-center bg-white outline-none' ref={setNodeRef}>
      <div className='d-flex align-items-center'>
        <DragIcon 
          
          {...listeners}
          className='cursor-pointer flex-shrink-0 mr-2 outline-none' 
          fontSize={'16px'} />
        <div className="label flex-shrink-0 ">{item?.tableLabel} - </div>
      </div>
      <div className='d-flex justify-content-between ml-4'>
        <div className="">{item.label} {generateSubOptionslabel(item, 1)} </div>
        {!(item.can_delete===false) && <span><span className='bg-light rounded p-1 pb-2'><DeleteIcon onClick={()=>{item?.handleDelete(item.index)}} className='cursor-pointer flex-shrink-0' width={'14px'} /></span></span>}
      </div>
  </div>
  );
};

