import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AppContainer from '../../components/AppContainer/AppContainer'
import { Tab, Tabs } from 'react-bootstrap';
import './AnalyticalReport.scss'
import QueryReport from './QueryReport/QueryReport';

const AnalyticalReport=(props)=> {
    const { history } = props;
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
        const activeTabData = localStorage.getItem('activeReportPage');
        setActiveTab(activeTabData ? activeTabData : 'queryReport');
    }, [])


    const handleTabSelect = (index) => {
        localStorage.setItem('activeReportPage', index);
        setActiveTab(index);
    };


    return (
        <AppContainer history={history}>
            <div className="event-table">
                <div className='UserMangTabsContainer'>
                    
                    {/* <Tabs id="controlled-tab-example"
                        className="TabsDiv"
                        activeKey={activeTab} onSelect={handleTabSelect}
                    >
                        <Tab eventKey="queryReport" title="Report" >
                        </Tab>
                        
                    </Tabs> */}
                    <QueryReport activeTab={activeTab} />
                </div>
            </div>
        </AppContainer >
    )
}

export default AnalyticalReport
