import { GET_ANANDA_KEY_CATEGORY_LIST, GET_QUERY_REPORT_DATA } from "../../constants";

export function getAnandaKeysCategoryList(data) {
    return {
        type: GET_ANANDA_KEY_CATEGORY_LIST,
        data,
    };
}

export function getQueryReport(data) {
    return {
        type: GET_QUERY_REPORT_DATA,
        data,
    };
}