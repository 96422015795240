import React, { useEffect, useState } from 'react'
import './ConsultantRatings.scss';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import moment from 'moment';
import Select from "react-select";
import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import DatePicker from 'react-datepicker'
import { visuallyHidden } from '@mui/utils';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { CircularProgress, Switch } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import calendarIcon from '../../../assets/images/CommonComponent/calendarIcon.png';
import { FormGroup, Label} from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import {stableSort, rowOptions} from '../../../utils/Helper'
import { createNotification } from '../../../Config/NotificationToast';
import { getConsultantRatings } from '../../../redux/actions/ReportAction/ConsultantRatingAction/ConsultantRatingAction';


const MainHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    if (orderBy === "duration") orderBy = "durationOne"
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: 'support_staff_name',
        disablePadding: false,
        label: 'CONSULTANT / THERAPIST',
        width: '20%'
    },
    {
        id: 'total',
        disablePadding: false,
        label: 'TOTAL',
        width: '10%'
    },
    {
        id: 'D',
        disablePadding: false,
        label: 'D',
        width: '10%'
    },
    {
        id: 'percent_D',
        disablePadding: false,
        label: '%',
        width: '10%'
    },
    {
        id: 'N',
        disablePadding: false,
        label: 'N',
        width: '10%'
    },
    {
        id: 'percent_N',
        disablePadding: false,
        label: '%',
        width: '10%'
    },
    {
        id: 'P',
        disablePadding: false,
        label: 'P',
        width: '10%'
    },
    {
        id: 'percent_P',
        disablePadding: false,
        label: '%',
        width: '10%'
    },
    {
        id: 'percent_score',
        disablePadding: false,
        label: 'QMS Score',
        width: '10%'
    }
];

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) =>
                (<TableCell
                    key={headCell.id}
                    align='left'
                    width={headCell.width}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    <TableSortLabel
                        
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        hideSortIcon={headCell.id === 'action' ? true : false}
                    >
                        <div style={{textAlign: "left"}}>
                            {headCell.label}
                        </div>
                        {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>)
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { pageNos, rowsPerPageNos, activeTab } = props;
    const dispatch = useDispatch();
    const localData = JSON.parse(localStorage.getItem("filtersReport"))
    const [searchText, setSearchText] = useState(localData?.consultantRatings?.filter_text ||'');
    const [fromDate, setFromDate] = useState(localData?.consultantRatings?.filter_from || format(new Date(), "yyyy-MM-01"));
    const [toDate, setToDate] = useState(localData?.consultantRatings?.filter_to || format(new Date(), "yyyy-MM-dd"));
  
    useEffect(() => {
        if (activeTab == "Consultant Ratings") {
            const localData = JSON.parse(localStorage.getItem("filtersReport"))
            if(!localData?.consultantRatings?.filter_text){
                setSearchText('')
            }

            if(!localData?.consultantRatings?.filter_from){
                setFromDate(format(new Date(), "yyyy-MM-01"))
            }

            if(!localData?.consultantRatings?.filter_to){
                setToDate(format(new Date(), "yyyy-MM-dd"))
            }
        }
    }, [activeTab]);

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem("filtersReport"))
        let filtersValues = { "filter_text": searchText, "filter_from": fromDate, "filter_to": fromDate !== toDate ? toDate : '', };
        localStorage.setItem("filtersReport", JSON.stringify({...localData, consultantRatings: filtersValues}))

    }, [searchText, fromDate, toDate])


    const changeFromDate = (val) => {
        const formattedDate = format(val, "yyyy-MM-dd");
        setFromDate(formattedDate)
        const sendRequest = {
            "pageNo": pageNos,
            "pageSize": rowsPerPageNos,
            "filter_text": searchText,
            "filter_from": formattedDate,
            "filter_to": fromDate !== toDate ? toDate : '',
        };
        dispatch(getConsultantRatings(sendRequest));
    }

    const changeToDate = (val) => {
        const formattedDate = format(val, "yyyy-MM-dd");
        setToDate(formattedDate)
        const sendRequest = {
            "pageNo": pageNos,
            "pageSize": rowsPerPageNos,
            "filter_text": searchText,
            "filter_from": fromDate,
            "filter_to": formattedDate,
        };
        dispatch(getConsultantRatings(sendRequest));
    }

    return (
        <>
            <Toolbar sx={{
                pl: { sm: 2 },
                pt: { sm: 2 },
                pb: { sm: 2 },
                pr: { xs: 1, sm: 1 },

            }}
            >
                <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-3'>
                    <MainHeading
                        sx={{ flex: '1 1 100%', }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Consultant Ratings
                    </MainHeading>
                </div>
                <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-10 col-xxl-9 LevelDiv'>
                    <div className='DurationDiv d-flex align-items-center'>
                        <Label>Date:</Label>
                        <div className='dateBlock'>
                            {fromDate == "" ?
                                <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">From</p></div>
                                : <div className="dayDateTextDiv" ><p className="dayDateText">{(moment(fromDate, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                            }
                            <div className='datePickerDiv'>
                                <DatePicker
                                    selected={new Date(moment(fromDate, "YYYY-MM-DD").format('MM-DD-YYYY'))}
                                    maxDate={new Date()}
                                    onChange={date => { changeFromDate(date) }}
                                    popperPlacement={"bottom"}
                                    customInput={
                                        <img src={calendarIcon} className="calIcon" alt="" />
                                    }
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                />
                            </div>
                        </div>
                        <div className='mx-2'>to</div>
                        <div className='dateBlock'>
                            {toDate == "" ?
                                <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">To</p></div>
                                : <div className="dayDateTextDiv" ><p className="dayDateText">{fromDate !== toDate && (moment(toDate, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                            }
                            <div className='datePickerDiv'>
                                <DatePicker
                                    selected={new Date(moment(toDate, "YYYY-MM-DD").format('MM-DD-YYYY'))}
                                    minDate={new Date((moment(fromDate, "YYYY-MM-DD").add(1, 'days')).format('MM-DD-YYYY'))}
                                    maxDate={new Date()}
                                    onChange={date => { changeToDate(date) }}
                                    popperPlacement={"bottom"}
                                    customInput={
                                        <img src={calendarIcon} className="calIcon" alt="" />
                                    }
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Toolbar>
            <Divider />
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const ConsultantRatingsTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showNext, setShowNext] = useState(false);
    const [showPrev, setShowPrev] = useState(false);
    const [viewConsultantRatings, setViewConsultantRatings] = useState('');
    const viewConsultantRatingsList = useSelector(state => state?.consultantRatings);
    const viewConsultantRatingsListDataRes = useSelector(state => state?.consultantRatings?.consultantRatings);
    const btnPrev = ["btnPrev", showPrev ? "" : "opacityBtns"];
    const btnNext = ["btnNext", showNext ? "" : "opacityBtns"];
    const { permission, activeTab } = props;
    const dispatch = useDispatch();



    useEffect(() => {
        if (activeTab == "Consultant Ratings") {
            const localData = JSON.parse(localStorage.getItem("filtersReport"))
        
            const sendRequest = {
                "pageNo": 1,
                "pageSize": rowsPerPage,
                "filter_text": localData?.consultantRatings?.filter_text || '' ,
                "filter_from": (localData?.consultantRatings?.filter_from) || format(new Date(), "yyyy-MM-01"),
                "filter_to": localData?.consultantRatings?.filter_to || (format(new Date(), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-01") ? '' : format(new Date(), "yyyy-MM-dd"))
            };
            dispatch(getConsultantRatings(sendRequest));
            console.log(activeTab)
        }
    }, [activeTab]);
    
    useEffect(() => {
        if (viewConsultantRatingsList.error !== "Ananda consultantRatings data does not exists") {
            setViewConsultantRatings(viewConsultantRatingsListDataRes && viewConsultantRatingsListDataRes);
            if (viewConsultantRatingsListDataRes?.data?.current_page_no === 1) {
                setShowPrev(false)
            } else {
                setShowPrev(true)
            }

            if (viewConsultantRatingsListDataRes?.data?.current_page_no === viewConsultantRatingsListDataRes?.data?.total_pages) {
                setShowNext(false)
            } else {
                setShowNext(true)
            }
        } else {
            setViewConsultantRatings('');
        }

    }, [viewConsultantRatingsListDataRes, viewConsultantRatingsList])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = viewConsultantRatingsListDataRes && viewConsultantRatings.map((n) => n?.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleChangePageNext = (newPage) => {
        setPage(newPage);
        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));
        const sendRequest = {
            "pageNo": viewConsultantRatingsListDataRes?.data?.current_page_no + 1,
            "pageSize": rowsPerPage,
            "filter_from": filterdata?.consultantRatings?.filter_from || format(new Date(), "yyyy-MM-01"),
            "filter_to": filterdata?.consultantRatings?.filter_to || format(new Date(), "yyyy-MM-dd"),
        };
        dispatch(getConsultantRatings(sendRequest));
    };
    
    const handleChangePagePrev = (newPage) => {
        setPage(newPage);
        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));
        const sendRequest = {
            "pageNo": viewConsultantRatingsListDataRes?.data?.current_page_no - 1,
            "pageSize": rowsPerPage,
            "filter_from": filterdata?.consultantRatings?.filter_from || format(new Date(), "yyyy-MM-01"),
            "filter_to": filterdata?.consultantRatings?.filter_to || format(new Date(), "yyyy-MM-dd"),
        };
        dispatch(getConsultantRatings(sendRequest));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.value);
        setPage(page);

        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));

        const sendRequest = {
            "pageNo": 1,
            "pageSize": event.value,
            "filter_from": filterdata?.consultantRatings?.filter_from || format(new Date(), "yyyy-MM-01"),
            "filter_to": filterdata?.consultantRatings?.filter_to || format(new Date(), "yyyy-MM-dd"),
        };
        dispatch(getConsultantRatings(sendRequest));
    };


    return (
        <div className="ProBox">
            <Box sx={{ width: '100%', height: 'calc(100vh - 200px)' }} className="ConsultantRatingsBox">
                <Paper sx={{ width: '100%', mb: 2, borderRadius: 2, height: viewConsultantRatings?.length ? '100%' : 'fit-content' }}>
                    <EnhancedTableToolbar 
                        numSelected={selected.length} 
                        pageNos={page}
                        showLoader={viewConsultantRatingsList.loading}
                        rowsPerPageNos={rowsPerPage} 
                        activeTab ={activeTab} />
                {
                    viewConsultantRatingsList.loading ?
                    <div className='w-100 text-center py-5'>
                        <CircularProgress sx={{'color': '#74613C'}}/>
                    </div>
                    :
                    <>
                        
                        {!!viewConsultantRatings?.length && <TableContainer sx={{ maxHeight: 'calc(100% - 90px)' }}>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                stickyHeader
                                size='small'
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={viewConsultantRatingsListDataRes && viewConsultantRatings?.length}
                                />
                                <TableBody>
                                    {
                                        stableSort(viewConsultantRatings, getComparator(order, orderBy))
                                            // .slice((rowsPerPage * (page - 1)), (rowsPerPage * (page) + rowsPerPage))
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow hover onClick={(event) => handleClick(event, row.title)} role="checkbox"

                                                            tabIndex={-1}
                                                            key={index}

                                                            >
                                                            <TableCell align="left">{row.support_staff_name}</TableCell>
                                                            <TableCell align="left">{row.total}</TableCell>
                                                            <TableCell align="left">{row.D}</TableCell>
                                                            <TableCell align="left">{row.percent_D}</TableCell>
                                                            <TableCell align="left">{row.N}</TableCell>
                                                            <TableCell align="left">{row.percent_N}</TableCell>
                                                            <TableCell align="left">{row.P}</TableCell>
                                                            <TableCell align="left">{row.percent_P}</TableCell>
                                                            <TableCell align="left">{row.percent_score}</TableCell>
                                                        </TableRow>
                                                        
                                                    </React.Fragment>
                                                );
                                            })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </>
                }
                </Paper>
                {/* {
                    viewConsultantRatings
                    &&
                    <div className='paginationBlock'>
                        <div className='textData'><p>Items per page: </p></div>
                        <FormGroup className="FormGroup has-float-label rowsSelect class-menu-dropdown ">
                            <Select
                                placeholder={rowsPerPage}
                                className="react-select"
                                classNamePrefix="react-select"
                                value={rowsPerPage}
                                options={rowOptions}
                                isSearchable={false}
                                onChange={handleChangeRowsPerPage}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F5F5F5',
                                        primary: '#74613C',
                                    },
                                })}
                                menuPlacement="top"
                                menuPosition="fixed"
                                styles={{
                                    menu: (base) => ({
                                        ...base,
                                        top: 'auto',
                                        bottom: 0,
                                    }),
                                }}
                            />
                        </FormGroup>
                        {viewConsultantRatingsListDataRes?.data && <p className='endText'>   {viewConsultantRatingsListDataRes?.data?.start} - {viewConsultantRatingsListDataRes?.data?.end} of {viewConsultantRatingsListDataRes?.data?.total}</p>}
                        <p>
                            <button className={btnPrev.join(' ')} onClick={() => { handleChangePagePrev(page) }}>
                                <ArrowBackIosNewSharpIcon className='iconBtn' />
                            </button>
                            <button className={btnNext.join(' ')} onClick={() => { handleChangePageNext(page) }}>
                                <ArrowForwardIosSharpIcon className='iconBtn' />
                            </button></p>
                    </div>   
                } */}
            </Box>
        </div>
    );
}
export default ConsultantRatingsTable;