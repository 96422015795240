import React from 'react'
import './RadioButton.scss'

function RadioButton({name, onChange=()=>{}, label, value, checked, disabled=false}) {
  return (
    <div className="custom-radio-input d-flex">
        <div className="radio d-flex align-items-center mr-2" onClick={()=>{onChange?.(value)}}>
            <input type="radio" name={name} onChange={()=>{}} checked={checked} value={value} disabled={disabled} />
            <label htmlFor="male"> {label} </label>
        </div>
    </div>
  )
}

export default RadioButton